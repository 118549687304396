import { Card, CardContent, Grid, Typography } from "@mui/material";
import jwt from "crypto-js";
import { onValue, ref } from "firebase/database";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./App.css";
import QR from "./Components/QR";
import { db } from "./firebase-config";

function App() {
  const { qrReducer } = useSelector((state: any) => state);
  const [smsData, setSmsData] = useState<Array<any> | null>(null);
  useEffect(() => {
    onValue(ref(db, qrReducer.document), (value) => {
      if (value.val() !== null) {
        setSmsData(
          JSON.parse(
            jwt.AES.decrypt(value.val(), qrReducer.secretKey).toString(
              jwt.enc.Utf8
            )
          )
        );
      } else {
        setSmsData(null);
      }
    });
  }, [qrReducer]);


  return (
    <Grid>
      {(smsData !== null &&
        smsData.map((val, index) => (
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {val.address}
              </Typography>
              <Typography>{val.body}</Typography>
              <Typography variant="caption" fontSize={14}>
                {new Date(val?.date).toLocaleDateString()===new Date().toLocaleDateString()?'':new Date(val?.date).toLocaleDateString()}{" "}
                {new Date(val?.date).toLocaleTimeString([],{hour:'2-digit',minute:'2-digit'})}
              </Typography>
            </CardContent>
          </Card>
        ))) || <QR />}
    </Grid>
  );
  // <div>
  //   <QR />
  // </div>
}

export default App;
