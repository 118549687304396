// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getDatabase} from 'firebase/database'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD7porCYrygJzzDhs61qF50KzgrKzqoc_0",
  authDomain: "sms-reader-8f51c.firebaseapp.com",
  databaseURL: "https://sms-reader-8f51c-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "sms-reader-8f51c",
  storageBucket: "sms-reader-8f51c.appspot.com",
  messagingSenderId: "961742835053",
  appId: "1:961742835053:web:6a203ea99b0cc96dbfde64",
  measurementId: "G-6KCZ2XY3T5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);