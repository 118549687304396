interface QRinfo {
    systemInfo: string;
    secretKey: string;
    document: string;
  }
  interface Action {
    type: string;
    payload: QRinfo;
  }
  
  export const qrDetails = (state: any = {}, action: Action) => {
    switch (action.type) {
      case "add":
        state = action.payload;
        return state;
      default:
        return state;
    }
  };
  
  
  