const addQrDetails = (qrInfo: any) => {
  return (dispatch: any) => {
    dispatch({
      type: "add",
      payload: qrInfo,
    });
  };
};


export const actionCreators = {
    addQrDetails
}