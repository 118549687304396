import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../Store/Actions";

interface QRinfo {
  systemInfo: string;
  secretKey: string;
  document: string;
}

const QR = () => {
  function secrekeyGenerator(length: number) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  const dispatch = useDispatch();
  const { addQrDetails } = bindActionCreators(actionCreators, dispatch);
  const [qrInfo, setQRinfo] = useState<Partial<QRinfo>>({});

  const getLocalKey = async (item: string) => {
    return localStorage.getItem(item);
  };

  const setLocalKey = async (key: string, value: string) => {
    await localStorage.setItem(key, value);
  };

  useEffect(() => {
    (async () => {
      const secretKey = await getLocalKey("secretKey");
      const document = await getLocalKey("document");

      if (secretKey && document) {
        setQRinfo({
          secretKey,
          document,
          systemInfo: window.navigator.userAgent,
        });
        addQrDetails({
          secretKey,
          document,
        });
      } else {
        const secretKey = secrekeyGenerator(50);
        const document = secrekeyGenerator(20);
        setQRinfo({
          secretKey,
          document,
          systemInfo: window.navigator.userAgent,
        });
        addQrDetails({
          secretKey,
          document,
        });
        await setLocalKey("secretKey", secretKey);
        await setLocalKey("document", document);
      }
    })();
  }, []);

  return (
    <Grid
      style={{ marginTop: "10%" }}
      direction="column"
      item
      container
      justifyContent={"center"}
      alignSelf="center"
      justifySelf={"center"}
      alignContent="center"
      justifyItems={"center"}
      alignItems={"center"}
    >
      {qrInfo?.secretKey && (
        <>
          <Typography fontSize={20} fontStyle={"italic"} style={{ margin: 20 }}>
            Scan this QR to connect your device
          </Typography>
          <QRCode value={JSON.stringify(qrInfo)} />
        </>
      )}
    </Grid>
  );
};

export default QR;
